import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import SearchItem from "../components/search-item";
import TopAdItem from "../components/top-ad-item";
import InlineAdItem from "../components/inline-ad-item";
import MediaCard from "../components/media-card";

import Pagination from "material-ui-flat-pagination";

import LoadingImage from "../images/loading.svg";


const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexShrink: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 8,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'normal',
      padding: 0,
    }
  },
  content: {
    maxWidth: '100%',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      padding: '0 12px 0 12px',
    }
  },
  items: {},
  info: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      paddingLeft: 40,
    },
    '& p': {
      fontSize: 13
    }
  },
  loading: {
    minWidth: 300,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
    [theme.breakpoints.up('md')]: {
      minWidth: 600,
    }
  },
  pagination: {
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
  },
});


class SearchList extends React.Component {

  componentDidMount() {}

  render() {
    // TODO: replace `handleTopAdHidden` with a general callback that can pass back which ad is hidden.
    const {
      classes,
      items,
      offset,
      onPageChange,
      isLoading,
      displayAds,
      handleTopAdHidden,
      deviceWidth,
      productCard,
    } = this.props;

    const isSmallScreen = deviceWidth && deviceWidth < 800;

    const buildItem = (item, index) => (
      <SearchItem key={index} url={item.url} title={item.name} snippet={shorten(item.snippet, 170)} />
    );

    const noSearchResults = !items.length;
    let topResults, bottomResults
    // Split search results up so we can render ads in the middle.
    if (noSearchResults) {
      topResults = (<p style={{ padding: 20 }}>No search results found.</p>);
    } else {
      topResults = items.map((item, index) => {
        if (index < 5) {
          return buildItem(item, index);
        }
        return null;
      });
      bottomResults = items.map((item, index) => {
        if (index > 4) {
          return buildItem(item, index);
        }
      });
    }

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {/* Loading Image */}
          <div className={classes.loading} hidden={!isLoading}>
            <img width='40' height='40' style={{ textAlign: 'center' }} src={`${LoadingImage}`} alt='loading' />
          </div>
          {/* Search Items */}
          <div className={classes.items} hidden={isLoading}>
            <TopAdItem hideAd={handleTopAdHidden} hidden={noSearchResults || !displayAds.top} />
            <InlineAdItem 
              containerId='inline-ad-container-top'
              hidden={noSearchResults || !displayAds.inlineTop}
            />
            {topResults}
            <InlineAdItem
              containerId='inline-ad-container-middle'
              hidden={noSearchResults || !displayAds.inlineMiddle}
            />
            {bottomResults}
            <InlineAdItem
              containerId='inline-ad-container-bottom'
              hidden={noSearchResults || !displayAds.inlineBottom}
            />
          </div>

          <div className={classes.pagination} hidden={isLoading || !items.length}>
            <Pagination
              limit={10}
              offset={offset}
              total={100}
              onClick={(e, offset) => onPageChange(offset)}
              size={'medium'}
            />
          </div>
        </div>
        <div className={classes.info} hidden={!productCard || isLoading}>
          {productCard ? (<MediaCard
            imageSrc={productCard.image}
            title={productCard.title}
            body={productCard.body}
            link={productCard.link}
          />) : ''}
        </div>
      </div>
    );
  }
}

function shorten(str, maxLength) {
  let _str = str;
  if (str.length > maxLength) {
    _str = str.slice(0, maxLength).split(' ');
    _str.pop();
    return _str.join(' ') + ' ...';
  }
  return _str;
}

SearchList.propTypes = {
  classes: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  offset: PropTypes.number,
  displayAds: PropTypes.object,
  deviceWidth: PropTypes.number,
  productCard: PropTypes.any,
}

SearchList.defaultProps = {
 items: [],
 isLoading: false,
 offset: 0,
 productCard: false,
}

export default withStyles(styles)(SearchList);
